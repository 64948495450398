import React from 'react';
import {
  Button, Popover, Typography, Box, useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'next-i18next';

import IconVerified from 'views/common/components/UI/Icons/IconVerified';
import Typography500 from 'views/common/components/UI/Typography500/Typography500';

const Root = styled('div')(({ theme }) => ({
  '& .labelBtn': {
    color: theme.palette.secondary.main,
    textTransform: 'none',
    whiteSpace: 'nowrap',
    textDecoration: 'underline',
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(14),
    letterSpacing: 1.25,
    '&:hover': {
      background: theme.palette.grey[50],
    },
  },
  '& .popoverArrow': {
    position: 'relative',
    '&::before': {
      content: '""',
      display: 'block',
      width: 0,
      height: 0,
      border: 'inset 0.5rem',
      borderColor: `transparent transparent ${theme.palette.grey['900']}`,
      borderBottomStyle: 'solid',
      borderTopWidth: 0,
      top: '-15px',
      position: 'absolute',
      right: '50%',
      marginRight: ' -1rem',
      transform: 'translateX(-50%)',
    },
  },
}));

const BestPriceGuarantee = (props) => {
  const { className } = props;
  const { t } = useTranslation('fe_er_best_price_guarantee');
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Root className={className}>
      <Button
        startIcon={<IconVerified />}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        className="labelBtn">
        {t('fe_er_best_price_guarantee:best_price_guarantee')}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        elevation={0}
        sx={{
          '&.MuiPopover-root': {
            pointerEvents: 'none',
          },
          '& .MuiPopover-paper': {
            backgroundColor: theme.palette.grey['900'],
            color: theme.palette.common.fogWhite,
            padding: theme.spacing(1),
            borderRadius: 0,
            overflow: 'visible',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        disableRestoreFocus>
        <Box className="popoverArrow" />
        <Typography500 variant="caption" display="block">
          {t('fe_er_best_price_guarantee:best_price_guarantee_title')}
        </Typography500>
        <Typography variant="caption" display="block">
          {t('fe_er_best_price_guarantee:best_price_guarantee_description')}
        </Typography>
      </Popover>
    </Root>
  );
};

export { BestPriceGuarantee };
