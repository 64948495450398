/**
const objectEX = {
  country: { name: 'United States', code: 'US',  },
  region: { name: 'California', code: 'CA' },
  geo: 'Los Angeles',
  postalCode: '12111',
  streetAddress1: 'old nasria',
  streetAddress2: 'Amreia',
};
 */

const addressFormat = (address) => {
  const {
    streetAddress1, streetAddress2, country, region, geo, postalCode,
  } = address;
  const fullAdress = `${streetAddress1 ? `${streetAddress1},` : ''} ${
    streetAddress2 ? `${streetAddress2}, ` : ''
  } ${geo ? `${geo}, ` : ''} ${region?.code ? `${region.code}` : ''} ${
    region && !region.code && region.name ? `${region.name}` : ''
  } ${postalCode ? `${postalCode},` : ''} ${country ? country.name : ''}`;
  return fullAdress;
};
export default addressFormat;
